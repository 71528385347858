import React from 'react';
import Layout from '../components/Layout';
import { StaticImage } from "gatsby-plugin-image"

const EasyLanguage = () => {
  return (
    <Layout pageTitle="Leichte Sprache" pageUrl="leichte-sprache">
      <section
        className="max-w-screen-lg pr-6 pl-6 m-auto"
        aria-labelledby="page-heading"
      >
        <h1
          id="page-heading"
          className="text-center lg:text-5xl text-2xl font-bold uppercase lg:mb-12 mb-6"
        >
          Leichte Sprache
        </h1>
        <div className="prose lg:prose-xl max-w-full">
          <h2>Herzlich willkommen!</h2>
          <p>
            Hier findest du Informationen in Leichter Sprache<br/>
            zur Internet-Seite »Wie klickst Du?«<br/>
            Diese Informationen haben 8 Teile:
          </p>

          <ol>
            <li><a href="#wer-hat-die-internet-seite-gemacht">Wer hat die Internet-Seite gemacht?</a></li>
            <li><a href="#hast-du-eine-frage">Hast du eine Frage?</a></li>
            <li><a href="#worum-geht-es-auf-dieser-internet-seite">Worum geht es auf dieser Internet-Seite?</a></li>
            <li><a href="#die-6-klima-schutz-typen">Die 6 Klima-Schutz-Typen</a></li>
            <li><a href="#welcher-klima-schutz-typ-bist-du">Welcher Klima-Schutz-Typ bist du?</a></li>
            <li><a href="#unsere-tipps-zum-klima-schutz">Unsere Tipps zum Klima-Schutz</a></li>
            <li><a href="#wie-benutzt-du-diese-internets-seite">Wie benutzt du diese Internet-Seite?</a></li>
            <li><a href="#erklärung-zur-barrierefreiheit">Erklärung zur Barrierefreiheit</a></li>
          </ol>

          <h3 id="wer-hat-die-internet-seite-gemacht">Wer hat die Internet-Seite gemacht?</h3>
          <p>
            Diese Internet-Seite ist vom Bundes-Presse-Amt.<br/>
            Das Bundes-Presse-Amt gehört zur Bundes-Regierung.<br/>
            Mehr Informationen zur Arbeit des Bundes-Presse-Amts<br/>
            finden Sie hier: <strong>Das macht das Bundes-Presse-Amt</strong>
          </p>
          <p>
            Der Text in Leichter Sprache ist von capito Berlin.
          </p>
          <StaticImage src="../images/image1.jpeg" alt="Das Gütesiegel Leicht Lesen A2 bestätigt: Mindestens drei Personen aus der Zielgruppe Menschen mit Lernschwierigkeiten haben den Text auf Verständlichkeit geprüft." />

          <h3 id="hast-du-eine-frage">Hast du eine Frage?</h3>
          <p>
            Möchtest du der Bundes-Regierung<br/>
            oder dem Bundes-Presse-Amt etwas mitteilen?<br/>
            Dann kannst du eine E-Mail schreiben oder dort anrufen.
          </p>
          <p>
            Die E-Mail-Adresse ist:<br/>
            <a href="mailto:internetpost@bpa.bund.de">internetpost@bpa.bund.de</a><br/>
            Oder klicke <a href="mailto:internetpost@bpa.bund.de">hier</a>:<br/>
            Dann kannst du über das Kontakt-Formular<br/>
            direkt eine Nachricht schreiben.
          </p>
          <p>
            Die Telefon-Nummer ist: 030 18 272 2720.<br/>
            Du kannst Montag bis Freitag von 8 bis 18 Uhr dort anrufen.
          </p>

          <h3 id="worum-geht-es-auf-dieser-internet-seite">Worum geht es auf dieser Internet-Seite?</h3>

          <p>
            Über diese Internet-Seite fragen wir dich regelmäßig,<br/>
            wie Du über politische Themen denkst.
          </p>
          <p>
            Dieses Mal geht es um den Klima-Schutz.<br/>
            Das Klima hat großen Einfluss auf unser Leben<br/>
            und auf die gesamte Natur.<br/>
            Jeder kann etwas für den Klima-Schutz tun.
          </p>
          <p>
            Auf unserer Erde wird es immer wärmer.<br/>
            Ein Grund dafür ist die Zunahme von CO2.<br/>
            CO2 ist die Abkürzung von Kohlen-Dioxid.<br/>
            CO2 ist ein natürliches Gas.<br/>
            Es entsteht aber auch als Abgas,<br/>
            zum Beispiel in der Industrie,<br/>
            beim Auto-Fahren oder Heizen.<br/>
            Die Menge an CO2 in unserer Umwelt<br/>
            ist heute 4 Mal mehr als vor 70 Jahren.<br/>
            Die Unternehmen müssen ihren CO2-Ausstoß sen<br/>ken.
            Aber auch jeder Einzelne kann seinen CO2-Ausstoß senken.<br/>
            Man kann zum Beispiel Strom sparen<br/>
            oder keine Lebensmittel wegwerfen.
          </p>
          <p>
            Der Klima-Wandel hat Einfluss auf unsere Öko-Systeme.<br/>
            Ein Öko-System ist zum Beispiel ein Wald oder ein See.<br/>
            Durch den Klima-Wandel schmelzen die Gletscher.<br/>
            Das Meer steigt an und bringt Inseln und Küsten in Gefahr.<br/>
            Es gibt sehr oft Stürme, Überschwemmungen oder Hitze-Wellen.<br/>
            Lange Trocken-Zeiten führen zu schlechten Ernten in der Landwirtschaft.<br/>
            Manche Tiere und Pflanzen können sich dem Klima-Wandel<br/>
            nicht so schnell anpassen und sterben aus.<br/>
            Wenn sich die Erde weiter erwärmt,<br/>
            hat das noch viele andere Folgen.
          </p>
          <p>
            Wenn wir das Klima schützen,<br/>
            erwärmt sich unsere Erde nicht so schnell.<br/>
            Für den Klima-Schutz müssen alle Länder zusammenarbeiten.<br/>
            Fast alle Länder haben dafür eine Vereinbarung unterschrieben.<br/>
            Die Vereinbarung heißt: Pariser Klima-Schutz-Abkommen.<br/>
            Darin steht, dass sich die Erde bis zum Jahr 2100<br/>
            um nicht mehr als 1,5 Grad erwärmen darf.<br/>
            Aber höchstens um 2 Grad.
          </p>
          <p>
            Auf dieser Internet-Seite findest du einen Test mit 35 Fragen<br/>
            zu deiner Haltung zum Klima.<br/>
            Dieser Test ist einfach zu bedienen.<br/>
            Klicke auf »Test starten« und los geht's!<br/>
            Es gibt Fragen mit 4 Antwort-Möglichkeiten.<br/>
            Und es gibt Fragen, bei denen du nur mit »Stimmt«<br/>
            oder »Stimmt nicht« antworten kannst.<br/>
            Dabei kannst du nichts falsch machen.<br/>
            Wenn du eine vorhergehende Antwort nochmal ändern möchtest,<br/>
            dann springe einfach mit dem Pfeil links oben eine Seite zurück.<br/>
            Klicke auch mal auf »Schon gewusst?«<br/>
            Dann bekommst du interessante Zusatz-Informationen<br/>
            zur aktuellen Frage im Test!
          </p>
          <p>
            Wir sagen dir, welcher Klima-Schutz-Typ du bist.<br/>
            Wir geben dir auch Tipps, was du für den Klima-Schutz tun kannst.
          </p>

          <h3 id="die-6-klima-schutz-typen">Die 6 Klima-Schutz-Typen</h3>
          <h4>Die Bewahrerin oder der Bewahrer</h4>
          <p>
            Dieser Typ möchte die Erde so erhalten wie sie ist.<br/>
            Dann bleibt auch das Klima so wie es ist.<br/>
            Dieser Typ möchte sein Verhalten nicht ändern.<br/>
            Aber neue Ideen zum Klima-Schutz findet er gut,<br/>
            zum Beispiel umweltfreundliche Technik.
          </p>

          <h4>Die Innovatorin oder der Innovator</h4>
          <p>
            Dieser Typ möchte klimafreundliche Technik,<br/>
            zum Beispiel mehr Digitalisierung.<br/>
            Er denkt, dass der Klima-Wandel damit aufgehalten werden kann.<br/>
            Er möchte alles verstehen und mit seinem Wissen<br/>
            gute Entscheidungen treffen,<br/>
            zum Beispiel Einkaufs-Entscheidungen.
          </p>
          <h4>Die Trend-Bewusste oder der Trend-Bewusste</h4>
          <p>
            Dieser Typ probiert gern neue Dinge aus,<br/>
            die gut für das Klima sind.<br/>
            Er spricht mit seinen Freunden darüber<br/>
            und liest viel dazu in den Sozialen Medien.
          </p>
          <h4>Die Idealistin oder der Idealist</h4>
          <p>
            Für diesen Typ ist der Klima-Schutz sehr wichtig.<br/>
            Er liest alle Nachrichten zum Klima-Schutz.<br/>
            Er spricht mit anderen darüber und<br/>
            tut selber jeden Tag etwas dafür.
          </p>
          <h4>Die Aktive oder der Aktive</h4>
          <p>
            Dieser Typ ist selbst aktiv<br/>
            und tut etwas für den Klima-Schutz.<br/>
            Er geht zum Beispiel demonstrieren.
          </p>
          <h4>Die Natur-Verbundene oder der Natur-Verbundene</h4>
          <p>
            Dieser Typ liebt die Natur.<br/>
            Er pflanzt Blumen oder sogar Bäume.<br/>
            Er kauft nur neue Sachen,<br/>
            wenn alte kaputt sind.
          </p>
          <h4 id="welcher-klima-schutz-typ-bist-du">Welcher Klima-Schutz-Typ bist du?</h4>
          <p>
            Kannst du dich einem Typen zuordnen?<br/>
            Denkst du auch so?<br/>
            Egal, welcher Typ du bist.<br/>
            Jeder kann etwas für den Klima-Schutz tun.<br/>
            Wir geben dir ein paar Tipps.
          </p>

          <h3 id="unsere-tipps-zum-klima-schutz">Unsere Tipps zum Klima-Schutz</h3>
          <h4>Rede mit deinen Freunden über das Klima!</h4>
          <p>
            Sage deinen Freunden nicht,<br/>
            was sie falsch machen.<br/>
            Sondern sage deinen Freunden,<br/>
            was du schon für den Klima-Schutz tust.<br/>
            Nenne ihnen Beispiele von dir wie
          </p>
          <ul>
            <li>eine schöne Reise mit dem Fahrrad,</li>
            <li>einen guter Second-Hand-Kauf,</li>
            <li>eine Reparatur von einem Gerät oder</li>
            <li>Gartenarbeit.</li>
          </ul>
          <h4>Stelle deinen Freunden Fragen!</h4>
          <p>
            Dann fangen deine Freunde an,<br/>
            über das Klima nachzudenken.<br/>
            Frage Sie zum Beispiel:
          </p>
          <ul>
            <li>Woher kommt dein Strom?</li>
            <li>Über welche Wege kommt das Obst in die Geschäfte?</li>
            <li>Was braucht man alles, um Kleidung zu produzieren?</li>
          </ul>
          <p>
            Der Strom, der Transport oder die Industrie verbrauchen für all das CO2.<br/>
            Deine Freunde können viel CO2 sparen,<br/>
            wenn sie mehr über diese Dinge wissen.<br/>
            Sie können außerdem ihren CO2-Verbrauch ausrechnen<br/>
            mit dem CO2-Rechner vom Umwelt-Bundesamt:<br/>
            <a href="https://uba.co2-rechner.de/de_DE/" target="_blank" rel="nofollow noopener noreferrer">https://uba.co2-rechner.de/de_DE/<span className="sr-only"> (öffnet ein neues Fenster)</span></a>
          </p>
          <h4>Achte auf klimafreundliches Essen!</h4>
          <p>
            Kaufe Obst und Gemüse aus deiner Region ein<br/>
            und zu der Jahreszeit, in der es wächst.<br/>
            Erdbeeren wachsen in Deutschland zum Beispiel im Juni.<br/>
            Wenn du Erdbeeren im Winter kaufst,<br/>
            kommen sie von weit her und schmecken anders.<br/>
            Kohl wächst im Winter bei uns.
          </p>
          <h4>Produziere weniger Müll!</h4>
          <p>
            Kaufe nur wenige oder keine Produkte mit Plastik-Verpackungen ein.<br/>
            Kaufe Getränke in Mehrweg-Flaschen.<br/>
            Du kannst auch im Bad viel Plastik sparen,<br/>
            wenn du zum Beispiel Seife nimmst statt Shampoo oder Flüssig-Seife.
          </p>
          <h4>Kaufe gebrauchte Kleidung oder Fair Fashion ein!</h4>
          <p>
            Brauchst du wirklich etwas neues zum Anziehen?<br/>
            Schaue zuerst, ob du das Kleidungs-Stück auch gebraucht bekommen<br/>
            kannst.<br/>
            Es gibt viele Geschäfte für Second-Hand-Sachen.<br/>
            Und auch Internet-Seiten dafür.<br/>
            Second Hand wird so ausgesprochen: seck-end hend.<br/>
            Es bedeutet aus 2. Hand.
          </p>
          <p>
            Wenn du etwas neu kaufst,<br/>
            achte auf eine faire Herstellung.<br/>
            Eine faire oder gerechte Herstellung bedeutet,<br/>
            dass die Beschäftigten in der Fabrik gute Arbeits-Bedingungen haben<br/>
            und einen gerechten Lohn für ihre Arbeit bekommen.
          </p>

          <h3 id="wie-benutzt-du-diese-internets-seite">Wie benutzt du diese Internet-Seite?</h3>
          <h4>Zurück zur Start-Seite</h4>
          <p>
            Wenn du links oben auf das Logo mit dem Bundes-Adler klickst,<br/>
            dann kommst du immer wieder zur Start-Seite zurück:
          </p>
          <StaticImage src="../images/image2.png" alt="Logo der Deutschen Bundes-Regierung mit schwarzem Bundes-Adler, daneben schmalem, senkrechten Farb-Band 'Schwarz, Rot, Gold' und Schriftzug 'Die Bundesregierung'" />
          <h4>Deutsche Gebärdensprache und Leichte Sprache</h4>
          <p>Oben rechts findest du diese Schalt-Flächen:</p>
          <StaticImage src="../images/image3.png" alt="Bildschirm-Foto der Schalt-Flächen am oberen Rand rechts mit Symbol 'Hände für Deutsche Gebärden-Sprache und Symbol 'Kopf über Buch' für Leichte Sprache" />

          <p>
            Hier findest du Videos in Deutscher Gebärden-Sprache<br/>
            über diese Internet-Seite.<br/>
            Gebärden-Sprache ist eine Zeichen-Sprache.<br/>
            Sie wird von Menschen benutzt,<br/>
            die nichts hören können.
          </p>
          <p>
            Im Bereich Leichte Sprache liest du gerade.<br/>
            Leichte Sprache ist für Menschen,<br/>
            die sich schnell und einfach informieren möchten.
          </p>

          <h4>Schalt-Flächen am unteren Rand</h4>

          <p>Am unteren Rand der Internet-Seite findest du diese Schalt-Flächen:</p>
          <StaticImage src="../images/image4.png" alt="Bildschirm-Foto der Schalt-Flächen am unteren Rand auf www.wieklickstdu.de" />

          <ol>
            <li>
              <p>Links zu den Einträgen über die Internet-Seite bei<br/>
              Social-Media-Kanälen<br/>
              Facebook, Instagram und Twitter sowie zum YouTube-Kanal</p>
            </li>
            <li>
              <p>Schalt-Fläche zu den Informationen in Deutscher Gebärden-Sprache<br/>
              und Leichter Sprache</p>
            </li>
            <li><p>Schalt-Fläche Erklärung zur Barrierefreiheit.</p></li>
            <li><p>Schalt-Fläche Barriere melden</p></li>
            <li>
              <p>Datenschutzerklärung:<br/>
              Die Internet-Seite sammelt bestimmte Daten,<br/>
              zum Beispiel über Ihren Internet-Browser.<br/>
              Dort erfahren Sie, wie Ihre Daten verwendet werden.</p>
            </li>
            <li>
              <p>Impressum:<br/>
              Hier steht, wer für die Internet-Seite verantwortlich ist.<br/>
              Das ist das Bundes-Presse-Amt.</p>
            </li>
            <li>
              <p>Kontakt:<br/>
              Hier findest du alle Kontakt-Informationen zum Bundes-Presse-Amt,<br/>
              zum Beispiel Telefon-Nummer und E-Mail-Adresse.</p>
            </li>
          </ol>

          <h3 id="erklärung-zur-barrierefreiheit">Erklärung zur Barrierefreiheit</h3>
          <p>
            Diese Erklärung gilt für die Internet-Seite <a href="https://www.wieklickstdu.de/" target="_blank" rel="nofollow noopener noreferrer">www.wieklickstdu.de<span className="sr-only"> (öffnet ein neues Fenster)</span></a>.<br/>
            Möglichst viele Menschen sollen die Seite nutzen können.<br/>
            Deshalb wurden die Regeln für Barriere-Freiheit<br/>
            bei der Programmierung und Gestaltung beachtet.<br/>
            Die Regeln stehen im Behinderten-Gleichstellungs-Gesetz, kurz BGG,<br/>
            und in der barrierefreien Informations-Technik-Verordnung, kurz BITV.
          </p>
          <p>
            Die Barriere-Freiheit dieser Internet-Seite wurde getestet.<br/>
            Dafür wurden einzelne Bereiche ausgewählt und genau untersucht,<br/>
            weil ein Test der ganzen Internet-Seite zu aufwändig ist.<br/>
            Nach dem Test kann man aber sicher sagen:<br/>
            Diese Internet-Seite ist barrierefrei nutzbar für Personen<br/>
            mit unterschiedlichen Einschränkungen.<br/>
            Diese Erklärung zu Barrierefreiheit ist vom 26. Februar 2021.
          </p>
          <h4>Probleme melden</h4>
          <p>
            Hast du Probleme bei der Nutzung dieser Internet-Seite?<br/>
            Klicke einfach unten rechts auf »Barriere melden«!<br/>
            Dann melde diese Probleme mit einer E-Mail an<br/>
            <a href="mailto:internetpost@bundesregierung.de">internetpost@bundesregierung.de</a>.
          </p>
          <p>Du kannst uns auch per Post oder telefonisch erreichen:</p>
          <p>
            Presse- und Informationsamt der Bundesregierung<br/>
            Redaktion Digital/Barrierefreiheit<br/>
            Dorotheenstraße 84<br/>
            10117 Berlin<br/>
            Telefon: 030 18 27 20
          </p>
          <p>
            Außerdem kannst du deine Anfrage<br/>
            auch in Deutscher Gebärdensprache stellen.<br/>
            Hier findest du weitere Informationen dazu,<br/>
            wie du deine Anfrage aufzeichnen kannst:<br/>
            <a href="https://www.bundesregierung.de/breg-de/gebaerdensprache/sqat/stellen-sie-ihre-anfrage-in-deutscher-gebaerdensprache-dgs--975928" target="_blank" rel="nofollow noopener noreferrer">https://www.bundesregierung.de/breg-de/gebaerdensprache/sqat/stellen-sie-ihre-anfrage-in-deutscher-gebaerdensprache-dgs--975928<span className="sr-only"> (öffnet ein neues Fenster)</span></a>
          </p>
          <h4>Schlichtungs-Verfahren</h4>
          <p>
            Hast du deine Probleme mit der Internet-Seite gemeldet<br/>
            und bist du nicht zufrieden mit der Reaktion der Verantwortlichen?<br/>
            Dann kannst du mit der Schlichtungs-Stelle Kontakt aufnehmen.<br/>
            Die Schlichtungs-Stelle vermittelt auch bei Problemen<br/>
            zwischen Menschen mit Behinderungen und öffentlichen Stellen des Bundes.<br/>
            Diese Vermittlung nennt man auch Schlichtungs-Verfahren.<br/>
            Das sind die Kontakt-Daten der Schlichtungs-Stelle:
          </p>
          <strong>Schlichtungsstelle nach dem Behindertengleichstellungsgesetz<br/>
          bei dem Beauftragten der Bundesregierung für die Belange von Menschen<br/>
          mit Behinderungen</strong>
          <p>
            Mauerstraße 53<br/>
            10117 Berlin<br/>
            Telefon: 030 185 27 28 05<br/>
            Fax: 030 185 27 29 01
          </p>
          <p>
            E-Mail: <a href="mailto:info@schlichtungsstelle-bgg.de">info@schlichtungsstelle-bgg.de</a><br/>
            Internet: <a href="http://www.schlichtungsstelle-bgg.de/" target="_blank" rel="nofollow noopener noreferrer">www.schlichtungsstelle-bgg.de<span className="sr-only"> (öffnet ein neues Fenster)</span></a>
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default EasyLanguage;
